import React from 'react'
import PropTypes from 'prop-types'

import Content from '../components/Content'

import '../styles/pages/blog_post.scss'

const BlogPostTemplate = ({ content, contentComponent, title, helmet }) => {
  const PostContent = contentComponent || Content

  return (
    <article className="blog_post">
      {helmet || ''}
      <h1>{title}</h1>
      <PostContent content={content} />
    </article>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

export default BlogPostTemplate
